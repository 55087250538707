import hoverintent from 'hoverintent';
import 'Modules/Shared/Menu/import/menu.js';

export default angular
  .module('directive.nav', ['menu.service'])

  .directive('rbMainNav', function () {
    return {
      restrict: 'A',
      bindToController: true,
      controllerAs: 'mainNavCtrl',
      /* @ngInject */
      controller: function ($rootScope, $scope, $element, $timeout, WindowEventsService, MenuService) {
        const ctrl = this;
        const hoverListeners = [];

        ctrl.open = false;
        ctrl.children = [];

        ctrl.toggle = () => {
          ctrl.open = !ctrl.open;
          let i;
          const len = ctrl.children.length;
          for (i = 0; i < len; i++) {
            ctrl.children[i].open = false;
          }
        };

        ctrl.nicescrollOptions = {
          horizrailenabled: false,
          autohidemode: 'scroll',
          cursorwidth: '6px',
          cursorcolor: '#999',
          cursorborder: '1px solid #999',
          cursorborderradius: '4px',
          railpadding: { top: 5, right: 0, left: 0, bottom: 5 },
          zindex: 1000000
        };

        ctrl.getNicescrollOptionsByStep = (alignLeft = false) => ({
          horizrailenabled: false,
          autohidemode: 'leave',
          railalign: alignLeft ? 'left' : 'right',
          cursorwidth: '4px',
          cursorcolor: '#DDDDDD',
          cursorborder: '1px solid #D9D9D9',
          cursorborderradius: '2px',
          railpadding: { top: 5, right: 0, left: 0, bottom: 5 },
          zindex: 1000000
        });

        const $nav = $element.children('.nav');
        let size = parseFloat($nav.css('font-size'));
        let padding = parseFloat($nav.children().eq(0).children('span').css('padding-left'));
        const origSize = size;
        ctrl.style = '';
        ctrl.menuStyle = '';
        resizeMenus();

        // Events

        WindowEventsService.listen(true, 'resize', resize, 500);

        $scope.$on('$destroy', () => {
          WindowEventsService.listen(false, 'resize', resize);
        });

        $rootScope.$on('toggleNav', () => {
          ctrl.toggle();
        });

        // Functions

        function resize() {
          ctrl.style = '';
          ctrl.menuStyle = '';
          $timeout(() => {
            size = parseFloat($nav.css('font-size'));
            padding = parseFloat($nav.children().eq(0).children('span').css('padding-left'));
            ctrl.active = false;
            size = origSize;
            $timeout(resizeMenus);
          });
        }

        function resizeMenus() {
          if ($scope.device.desktop) {
            if ($nav.width() > $element.width()) {
              if (padding > 5) {
                padding -= 1;
                ctrl.menuStyle = { 'padding-left': `${padding}px`, 'padding-right': `${padding}px` };
              } else {
                size -= 0.5;
                ctrl.style = { 'font-size': `${size}px` };
              }
              $timeout(resizeMenus);
            } else {
              ctrl.active = true;
            }
          } else {
            ctrl.style = '';
            ctrl.menuStyle = '';
            ctrl.active = true;
          }
        }

        ctrl.showDefaultSubmenu = false;
        ctrl.navMenu = null;

        $scope.initDesktopNavBySteps = showDefaultSubmenu => {
          ctrl.showDefaultSubmenu = showDefaultSubmenu;

          const $menuItems = $element.find('.menu-item.level1');
          ctrl.navMenu = $element.find('#navMenu.hidden');
          if (ctrl.navMenu) {
            ctrl.navMenu.removeClass('hidden');
          }
          $menuItems.each((key, elmt) => {
            const $menuItem = $(elmt);
            const $menus = $menuItem
              .filter((index, element) => $(element).find('.menu').length > 0)
              .children('.menu')
              .children('.menu-by-step')
              .children('.menu-item.level2');
            const $menuHover = $menuItem.children('.menu-hover');

            $menus.each((index, element) => {
              const listener = hoverintent(
                element,
                function () {
                  $element.find('.menu-item.level2').children('.is-active').removeClass('is-active');
                  $(this).children('.menu-item-link').addClass('is-active');

                  const $subMenuHover = $(this).children('.submenu-hover');
                  MenuService.showMenuHover($menuHover, $subMenuHover, $(this).parent().parent().height());
                },
                function () { }
              ).options({
                timeout: 100,
                interval: 100
              });
              hoverListeners.push(listener);
            });
          });

          document.addEventListener(
            'scroll',
            event => {
              const subMenuHovers = ctrl.navMenu.find('.submenu-hover.is-active');
              if (subMenuHovers.length > 0) {
                subMenuHovers.each((index, subMenuHover) => {
                  const menu = $(subMenuHover).parent().parent().parent();
                  if (menu) {
                    MenuService.setSubMenuHoverPosition($(subMenuHover), menu);
                  }
                });
              }
            },
            { passive: true }
          );
        };
      }
    };
  })

  /* @ngInject */
  .directive('rbNav', function ($document, $animate) {
    return {
      restrict: 'A',
      scope: {
        psOpen: '=?'
      },
      link: (scope, element) => {
        element.addClass('ng-pageslide');

        const body = document.body;
        const $body = $(body);
        const $mainNav = $('#main-nav');
        const $navMenu = $('#navMenu.mobile');
        const slider = element[0];

        function onBodyClick(e) {
          if (scope.psOpen && !slider.contains(e.target)) {
            scope.$apply(() => {
              scope.psOpen = false;
            });
          }
        }

        $body.addClass('offcanvas');

        if (slider.children.length === 0) {
          throw new Error('You need to have content inside the <pageslide>');
        }

        // Closed
        function psClose() {
          if ($body.hasClass('offcanvas-left')) {
            $('.move-out').removeClass('move-out'); // TODO
            $animate.removeClass(body, 'offcanvas-left').then(closeDone);
            $document.off('click', onBodyClick);
          }
        }
        function closeDone() {
          scope.psOpen = false;
        }
        // Open
        function psOpen() {
          if (!$body.hasClass('offcanvas-left')) {
            $navMenu.css('top', $mainNav.position().top + $mainNav.height() - $(window).scrollTop());
            $animate.addClass(body, 'offcanvas-left').then(openDone);
            $document.on('click', onBodyClick);
          }
        }
        function openDone() {
          scope.psOpen = true;
        }

        // Watchers

        scope.$watch('psOpen', value => {
          if (!!value) {
            psOpen();
          } else {
            psClose();
          }
        });

        // Events

        scope.$on('$destroy', () => {
          if (slider.parentNode === body) {
            $document.off('click', onBodyClick);
          }
        });

        scope.$on('$locationChangeStart', () => {
          psClose();
        });
      }
    };
  })

  /* @ngInject */
  .directive('rbMenu', function ($animate, $injector, $location, $document) {
    const $animateCss = $injector.has('$animateCss') ? $injector.get('$animateCss') : null;
    return {
      restrict: 'A',
      scope: true,
      link: (scope, element) => {
        scope.mainNavCtrl.children.push(scope);
        //
        scope.open = false;
        scope.element = element;

        initMenu();
        initLevelsLinks();

        scope.expandMenu = () => {
          const $menu = element.addClass('in').children('.menu');
          const $nicescrollbar = $document.find('.nicescroll-rails'); // Permet de cibler la barre de défillement de type "nicescroll" du menu.
          $nicescrollbar.css({ visibility: 'hidden' }); // Lors du déploiement du menu, ajoute à la barre "nicescroll" le paramètre CSS "visibility:" à "hidden" afin de corriger le bug d’apparition succincte de la barre de défilement "nicescroll" dû au fait que le menu est en cours de déploiement.
          element.children('a').addClass('in');

          $menu
            .addClass('collapsing')
            .attr('aria-expanded', true)
            .attr('aria-hidden', false)
            .css({ overflow: 'hidden' }); // Permet de corriger le bug d’apparition succincte de la barre de défilement native du navigateur dû au fait que le menu est en cours de déploiement.

          if (scope.$parent.device.isTouch) {
            $('#main-nav').addClass('in');
            $menu.after('<div class="backdrop"/>');
            element.children('.backdrop').on('click', function () {
              $(this).remove();
              scope.reduceMenu();
            });
          }

          function expandDone() {
            $menu.removeClass('collapsing').css({ height: 'auto', overflow: '' }); // Suppression du paramètre CSS "overflow:" à la fin du déploiement du menu afin de resetter la correction du bug d’apparition succincte de la barre de défilement native du navigateur.
            setTimeout(() => { // Suppression du paramètre CSS "visibility:" à la fin du déploiement du menu afin de resetter la correction du bug d’apparition succincte de la barre de défilement "nicescroll".
              $nicescrollbar.css({ visibility: '' });
            }, 350);
          }

          if ($animateCss) {
            $animateCss($menu, {
              addClass: 'in',
              easing: 'ease',
              to: { height: `${$menu.length > 0 ? $menu[0].scrollHeight : 0}px` }
            })
              .start()
              .finally(expandDone);
          } else {
            $animate
              .addClass($menu, 'in', {
                to: { height: `${$menu.length > 0 ? $menu[0].scrollHeight : 0}px` }
              })
              .then(expandDone);
          }

          setTimeout(() => {
            $(window).trigger('scroll');
          }, 350);
        };

        scope.reduceMenu = () => {
          const $menu = element.removeClass('in').children('.menu');
          if (!$menu.length) return;

          element.children('a').removeClass('in');
          element.children('.backdrop').remove();
          $('#main-nav').removeClass('in');

          $menu
            .css({ height: `${$menu.length > 0 ? $menu[0].scrollHeight : 0}px`, overflow: 'hidden' }) // "overflow: 'hidden'" permet de corriger le bug d’apparition succincte de la barre de défilement native du navigateur dû au fait que le menu est en cours de réduction.
            .addClass('collapsing')
            .attr('aria-expanded', false)
            .attr('aria-hidden', true);

          function collapseDone() {
            $menu.css({ height: '0', overflow: '' }).removeClass('collapsing'); // Suppression du paramètre CSS "overflow:" à la fin de la réduction du menu afin de resetter la correction du bug d’apparition succincte de la barre de défilement native du navigateur.
          }

          if ($animateCss) {
            $animateCss($menu, {
              removeClass: 'in',
              to: { height: '0' }
            })
              .start()
              .finally(collapseDone);
          } else {
            $animate
              .removeClass($menu, 'in', {
                to: { height: '0' }
              })
              .then(collapseDone);
          }
        };

        scope.openMenu = event => {
          if (window.isBot || window.isCache) return;
          event.preventDefault();
          if (!scope.$parent.device.desktop) {
            scope.open = !scope.open;
            element.siblings().addClass('move-out').end().closest('.nav').scrollTop(0);
          } else {
            if (scope.$parent.device.isTouch && element.hasClass('level1')) {
              const $menu = element.children('.menu');
              if (!$menu.hasClass('collapsing') && !$menu.hasClass('in')) {
                const ctrl = scope.mainNavCtrl;
                let i;
                const len = ctrl.children.length;
                for (i = 0; i < len; i++) {
                  if (ctrl.children[i].element !== element && ctrl.children[i].element.children('.menu.in').length) {
                    ctrl.children[i].reduceMenu();
                  }
                }
                scope.expandMenu();
              }
              return;
            }
            $location.path(element.children('a').attr('href'));
          }
        };

        scope.backMenu = event => {
          event.preventDefault();
          scope.open = !scope.open;
          element.siblings().removeClass('move-out');
        };

        scope.closeMenu = () => {
          scope.mainNavCtrl.open = false;
        };

        // Functions

        function initMenu() {
          if (scope.$parent.device.isTouch || !scope.$parent.device.desktop || !element.hasClass('level1')) return;

          hoverintent(
            element[0],
            () => {
              scope.expandMenu();
            },
            () => {
              scope.reduceMenu();
            }
          ).options({
            timeout: 200,
            interval: 100
          });
        }

        function initLevelsLinks() {
          element.find('.menu-list').on('click', 'a', event => {
            if (scope.device.desktop) {
              scope.reduceMenu();
            }
          });
        }
      }
    };
  })

  /* @ngInject */
  .directive('rbMenuByStep', function ($animate, $injector, $location, MenuService) {
    const $animateCss = $injector.has('$animateCss') ? $injector.get('$animateCss') : null;
    const menuHeight = 400;

    return {
      restrict: 'A',
      scope: true,
      link: (scope, element) => {
        scope.mainNavCtrl.children.push(scope);
        //
        scope.open = false;
        scope.element = element;

        scope.expandMenu = () => {
          const $menu = element.addClass('is-active').children('.menu');
          element.children('a').addClass('is-active');
          const $menuHover = element.children('.menu-hover');
          let subMenuHover = null;
          if (scope.mainNavCtrl.showDefaultSubmenu) {
            subMenuHover = element.find('.submenu-hover').first();
          }

          $menu.addClass('collapsing').addClass('is-active').attr('aria-expanded', true).attr('aria-hidden', false);

          if (scope.$parent.device.isTouch) {
            $('#main-nav').addClass('is-active');
            $menu.after('<div class="backdrop"/>');
            element.children('.backdrop').on('click', function () {
              $(this).remove();
              scope.reduceMenu();
            });
          }

          function expandDone() {
            MenuService.showMenuHover($menuHover, subMenuHover, $menu.height());
            $menu.removeClass('collapsing').addClass('expanded');
          }

          const child = $menu.children().first();
          let childHeight = 0;
          if (child) {
            childHeight = Math.max(menuHeight, child.outerHeight(), child.scrollTop());
          }

          if ($animateCss) {
            $animateCss($menu, {
              addClass: 'is-active',
              easing: 'ease',
              to: { height: `${childHeight}px` }
            })
              .start()
              .finally(expandDone);
          } else {
            $animate
              .addClass($menu, 'is-active', {
                to: { height: `${childHeight}px` }
              })
              .then(expandDone);
          }

          setTimeout(() => {
            $(window).trigger('scroll');
          }, 350);
        };

        scope.reduceMenu = () => {
          // wait reducing menu before open an another menu
          element.css('pointer-events', 'none');
          const $menu = element.children('.menu');
          if (!$menu.length) return;

          element.children('.backdrop').remove();
          $menu.children('.menu-by-step').removeClass('is-active');
          $menu.find('.submenu-hover.is-active').removeClass('is-active');
          $menu.next('.menu-hover').removeClass('is-active');

          const child = $menu.children().first();
          let childHeight = 0;
          if (child) {
            childHeight = Math.max(menuHeight, child.scrollTop());
          }

          $menu
            .css({ height: `${childHeight}px` })
            .addClass('collapsing')
            .attr('aria-expanded', false)
            .attr('aria-hidden', true)
            .removeClass('expanded');

          function collapseDone() {
            $menu.css({ height: '0' }).removeClass('collapsing').removeClass('is-active');
            element.removeClass('is-active');
            element.children('a').removeClass('is-active');
            $('#main-nav').removeClass('is-active');
            if (child) {
              child.removeClass('is-active');
            }
            element.css('pointer-events', 'auto');
          }

          if ($animateCss) {
            $animateCss($menu, {
              to: { height: '0' }
            })
              .start()
              .finally(collapseDone);
          } else {
            $animate
              .removeClass($menu, '', {
                to: { height: '0' }
              })
              .then(collapseDone);
          }
        };

        scope.openMenu = event => {
          if (window.isBot || window.isCache) return;
          event.preventDefault();
          if (!scope.$parent.device.desktop) {
            scope.open = !scope.open;
            element.siblings().addClass('move-out').end().closest('.nav').scrollTop(0);
          } else {
            if (scope.$parent.device.isTouch && element.hasClass('level1')) {
              const $menu = element.children('.menu');
              if (!$menu.hasClass('collapsing') && !$menu.hasClass('is-active')) {
                const ctrl = scope.mainNavCtrl;
                let i;
                const len = ctrl.children.length;
                for (i = 0; i < len; i++) {
                  if (ctrl.children[i].element !== element && ctrl.children[i].element.children('.menu.is-active').length) {
                    ctrl.children[i].reduceMenu();
                  }
                }
                scope.expandMenu();
              }
              return;
            }
            $location.path(element.children('a').attr('href'));
          }
        };

        scope.backMenu = event => {
          event.preventDefault();
          scope.open = !scope.open;
          element.siblings().removeClass('move-out');
        };

        scope.closeMenu = () => {
          scope.mainNavCtrl.open = false;
        };

        initMenu();
        initLevelsLinks();

        function initMenu() {
          if (scope.$parent.device.isTouch || !scope.$parent.device.desktop || !element.hasClass('level1')) return;

          // La transition css a besoin d'une valeur de depart
          element.find('.menu').css('height', 0);

          hoverintent(
            element[0],
            () => {
              scope.expandMenu();
            },
            () => {
              scope.reduceMenu();
            }
          ).options({
            timeout: 0,
            interval: 200,
            sensitivity: 1
          });
        }

        function initLevelsLinks() {
          element.find('.menu-list').on('click', 'a', event => {
            if (scope.device.desktop) {
              scope.reduceMenu();
            }
          });
        }
      }
    };
  });
